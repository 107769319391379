@use "abstracts" as abs;
@use "components";
@use "layout";
@use "pages";
/* The following line can be included in a src/App.scss */

body {
  font-family: abs.$font-family !important;
}

.container {

  @include abs.respond-to(mobile) {
    max-width: 540px;
  }
  @include abs.respond-to(handheld) {
    max-width: 100%;
  }
  @include abs.respond-to(smallDesktop) {
    max-width: 984px ;
    padding: 0px 24px;
  }
  @include abs.respond-to(largeDesktop) {
    max-width: 1160px;
    padding: 0px 24px;
  }
  @include abs.respond-to(extraLargeDesktop) {
    max-width: 1280px;
    padding: 0px 24px;
  }
}

section {
  margin: 0px 0px 40px 0px;
  overflow: hidden !important;
  @include abs.respond-to(mobile) {
    margin: 10px 0px;
  }

  &:first-child {
    //margin-top: 0px;
  }
  &:last-child {
    margin-bottom: 0px;

    @include abs.respond-to(mobile) {
      margin-bottom: 20px;
    }
  }
}

body {
  background-color: #fff;
}

.f-body {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}
