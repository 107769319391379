@use "../abstracts" as abs;
.hero {
  .main {
    position: relative;
    .text {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 100%;
      background-color: #eef5f7cc;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      align-items: center;
      text-align: center;

      @include abs.respond-to(handheld) {
        max-width: 420px;
        padding: 40px;
      } 
      
      @include abs.respond-to(mobile) {
        position: relative;
        padding: 26px 0px;
      }

      .img{
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
        img{
          width: 80%;

          @include abs.respond-to(mobile) {
            width: 50%;
          }
        }
      }

      .h1 {
        width: 100%;
        font-size: 18px;
        line-height: 1.25;
        letter-spacing: 0.125em;
        font-weight: 400;
        color: #667378;
        overflow-wrap: break-word;
        text-transform: uppercase;
        margin-bottom: 16px;
      }

      .para {
        width: 100%;
        font-size: 18px;
        line-height: 1.5;
        letter-spacing: normal;
        font-weight: 400;
        color: #575757;
        margin: 16px 0px;

        @include abs.respond-to(mobile) {
        padding: 0px 35px;
        }
      }
    }
  }
}

.ourapproach {
  
  .approachitem {
    padding: 0px 24px;

    @include abs.respond-to(mobile) {
      margin: 15px 0px;
    }
    .main {
      background-color: rgb(238, 245, 247);
      height: 100%;
      .img {
        margin-bottom: 24px;
        img {
          width: 100%;
        }
      }

      .title {
        min-height: 60px;
        margin-bottom: 24px;
        padding: 0px 32px;
        text-align: center;
        font-size: 24px;
        color: rgb(27, 27, 27);
        letter-spacing: 0.045em;
        line-height: 1.25;
      }

      .para {
        min-height: 60px;
        margin-bottom: 24px;
        padding: 0px 32px;
        text-align: center;
        font-size: 18px;
        color: rgb(94, 94, 94);
        letter-spacing: normal;
        line-height: 1.5;
        font-weight: 400;
      }
    }
  }
}

.rev-sect {
  margin-bottom: 0px;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.24) 0%,
      rgba(0, 0, 0, 0.24) 100%
    ),
    url(../../static/home/rev-back.webp);
  background-repeat: no-repeat;
  background-blend-mode: normal;
  background-size: auto, cover;
  background-position: 50% 50%;
  .main {
    padding: 56px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
      
      color: rgb(255, 255, 255);
      
    }

    .rev-list {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      overflow-wrap: break-word;
      color: rgb(255, 255, 255);
      text-shadow: 0px 2px 30px rgba(0, 0, 0, 0.12);
      padding: 16px 24px;
      background-color: rgba(22, 22, 22, 0.5);
      width: 55%;
      text-align: center;

      @include abs.respond-to(mobile) {
        width: 80%;
      }
    }
  }
}

.gallery {
  padding-bottom: 92px;

  @include abs.respond-to(mobile) {
    padding-bottom: 20px;

  }
  .title {
    font-size: 22px;
    color: rgb(27, 27, 27);
    line-height: 1.4;
    font-weight: 400;
    letter-spacing: 0.045em;
    overflow-wrap: break-word;
    margin-bottom: 24px;

    @include abs.respond-to(extraLargeDesktop) {
      font-size: 24px;
    }
    @include abs.respond-to(handheld) {
      margin-bottom: 32px;
    }
  }

  .gal-item {
    height: 650px !important;
    width: 100%;
    margin: 0px 5px;

    @include abs.respond-to(mobile) {
      height: 350px !important;

    }

    img {
      height: 650px;
      max-height: 650px;

      @include abs.respond-to(mobile) {
        height: 350px !important;
        max-height: 350px;
  
      }
      width: auto;
    }

    &:focus-visible {
      outline: none !important;
    }
  }

  .slick-dots {
    bottom: -92px;
    @include abs.respond-to(mobile) {
      bottom: 67px;

    }
    li {
      position: relative;
      display: inline-block;
      width: 70px;
      height: 70px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      overflow: hidden;

      &.slick-active {
        scale: 1.12;
      }

      a {
        height: 100%;
        display: block;
        width: 100%;
        img {
          height: 100%;
          width: fit-content;
        }
      }
    }
  }
}

.about-us{
  .main{
    display: grid;
    grid-template-areas: "text map";
    grid-template-columns: 50% 50%;

    @include abs.respond-to(mobile) {
      grid-template-areas:  "map" "text";
      grid-template-columns: 100%;
    }

    .textDiv{
      grid-area: text;
      background-color: rgb(238, 245, 247);
      padding: 56px 40px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: start;

      @include abs.respond-to(mobile) {
        padding: 36px 20px;

      }

      .text1{
        
        color: rgb(107, 121, 125);
        
        margin-bottom: 32px;
      }
      .text2{
        font-size: 18px;
        color: rgb(107, 121, 125);
        line-height: 1.25;
        letter-spacing: 0.125em;
        font-weight: 400;
        text-transform: uppercase;
        display: block;
        margin-bottom: 24px;

        @include abs.respond-to(mobile) {
          font-size: 14px;
  
        }
      }
      .text3{
        font-size: 18px;
        color: rgb(94, 94, 94);
        line-height: 1.5;
        font-weight: 400;
        display: block;
        margin-bottom: 24px;
        font-style: italic;
        @include abs.respond-to(mobile) {
          font-size: 14px;
  
        }
      }
      
      .text4{
        font-size: 18px;
        color: rgb(107, 121, 125);
        line-height: 1.4;
        letter-spacing: 0.125em;
        font-weight: 400;
        text-transform: uppercase;
        display: block;
        margin-bottom: 24px;
        @include abs.respond-to(mobile) {
          font-size: 14px;
  
        }
      }
      
      .text5{
        font-size: 18px;
        color: rgb(94, 94, 94);
        line-height: 1.5;
        font-weight: 400;
        display: block;
        margin-bottom: 24px;
        @include abs.respond-to(mobile) {
          font-size: 14px;
  
        }
      }
      .text6{
        font-size: 18px;
        color: rgb(94, 94, 94);
        line-height: 1.5;
        font-weight: 400;
        display: block;
        margin-bottom: 5px;
        @include abs.respond-to(mobile) {
          font-size: 14px;
  
        }

        a{
          text-decoration: none;
          color: inherit !important;
        }
      }
      .text7{
        font-size: 18px;
        color: rgb(94, 94, 94);
        line-height: 1.5;
        font-weight: 400;
        display: block;
        margin-bottom: 24px;
        @include abs.respond-to(mobile) {
          font-size: 14px;
  
        }

        a{
          text-decoration: none;
          color: inherit !important;
        }
      }
      .text8{
        font-size: 18px;
        color: rgb(107, 121, 125);
        line-height: 1.4;
        letter-spacing: 0.125em;
        font-weight: 400;
        text-transform: uppercase;
        display: block;
        margin-bottom: 24px;
        @include abs.respond-to(mobile) {
          font-size: 14px;
  
        }
      }
    }
    .textMap{
      grid-area: map;

      @include abs.respond-to(mobile) {
        height: 366px;
      }
      #map{
        height: 100%;
        width: 100%;
      }
    }
  }
}

.generalHeader{
  font-size: 22px;
  color: rgb(27, 27, 27);
  line-height: 1.4;
  font-weight: 400;
  letter-spacing: 0.045em;
  overflow-wrap: break-word;
  margin-bottom: 24px;

  @include abs.respond-to(extraLargeDesktop) {
    font-size: 24px;
  }
  @include abs.respond-to(handheld) {
    margin-bottom: 32px;
  }
}
