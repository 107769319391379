@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
$font-family: "Montserrat", sans-serif;
$font-family2: "Archivo Black", sans-serif;

.fs-1 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-2 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-3 {
  font-size: calc(1.26rem + 0.12vw) !important;
}

.fs-4 {
  font-size: 1.25rem !important;
}

.fs-5 {
  font-size: 1.15rem !important;
}

.fs-6 {
  font-size: 1.075rem !important;
}

.fs-7 {
  font-size: 0.95rem !important;
}

.fs-8 {
  font-size: 0.85rem !important;
}

.fs-9 {
  font-size: 0.75rem !important;
}

.fs-10 {
  font-size: 0.5rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-fluid {
  font-size: 100% !important;
}

.fs-2x {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-2qx {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2hx {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2tx {
  font-size: calc(1.4rem + 1.8vw) !important;
}

.fs-3x {
  font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-3qx {
  font-size: calc(1.45rem + 2.4vw) !important;
}

.fs-3hx {
  font-size: calc(1.475rem + 2.7vw) !important;
}

.fs-3tx {
  font-size: calc(1.5rem + 3vw) !important;
}

.fs-4x {
  font-size: calc(1.525rem + 3.3vw) !important;
}

.fs-4qx {
  font-size: calc(1.55rem + 3.6vw) !important;
}

.fs-4hx {
  font-size: calc(1.575rem + 3.9vw) !important;
}

.fs-4tx {
  font-size: calc(1.6rem + 4.2vw) !important;
}

.fs-5x {
  font-size: calc(1.625rem + 4.5vw) !important;
}

.fs-5qx {
  font-size: calc(1.65rem + 4.8vw) !important;
}

.fs-5hx {
  font-size: calc(1.675rem + 5.1vw) !important;
}

.fs-5tx {
  font-size: calc(1.7rem + 5.4vw) !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fw-bolder {
  font-weight: 600 !important;
}
