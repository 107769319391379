@use "../abstracts" as abs;

header {

  &> .container{
    padding: 32px 24px;
    margin-bottom: 24px;

    display: flex;
 justify-content: center;
 align-items: center;
 position: relative;
 

 .phone{
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  a{
    text-decoration: none;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(102, 115, 120);
    overflow-wrap: break-word;
  
  }
 }
  }
 

  @include abs.respond-to(mobile) {
 
  }
}

