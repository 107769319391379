@use "../../abstracts" as abs;

.btn-wbc {
  font-size: 16px;
  background-color: transparent;
  min-height: 56px;
  padding: 8px 40px;
  text-shadow: none;
  font-weight: 700;
  border-width: 1px;
  border-radius: 48px;
  border-color: black;
  display: inline-flex;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
  border-style: solid;
  letter-spacing: 0.214em;
  color: rgb(107, 121, 125);
  cursor: pointer;
  justify-content: center;
  align-items: center;
text-decoration: none;
  &:hover{
    border-color: rgb(199, 222, 229);
    background-color: rgb(199, 222, 229);
    color: rgb(21, 22, 22);
    outline: 0;
  }
}
