@use "../abstracts" as abs;
footer {
    font-size: 14px;
    letter-spacing: 0.083em;
    color: rgb(89, 89, 89);
    font-weight: 400;
    line-height: 1.5;
    text-transform: uppercase;
    padding: 56px 0px;
    text-align: center;

    @include abs.respond-to(mobile) {
        font-size: 11px;

        padding: 26px 10px;

      }
}
